import { Decimal } from 'decimal.js'
import { isNil } from 'lodash'
import InlineMath from '../components/Shared/InlineMath'
import { latexENotationRegEx, latexSciNotationRegEx } from '../constants/variable'
import { COMPLEX_NUMBER_FORMAT_TYPES, NUMBER_FORMAT_TYPE } from '../types/NumberFormatType'
import { TOLERANCE_TYPE } from '../types/Solution'
import { RandomNumberVariableParameters } from '../types/Variable'
import { formatRandomNumberValue } from './variable'

export const textForNumberFormat = (format: string): string | JSX.Element => {
	switch (format) {
		case NUMBER_FORMAT_TYPE.NUMBER_WITH_SEPARATORS:
			return 'Number with Separators'
		case NUMBER_FORMAT_TYPE.SCI_NOTATION:
			return 'Scientific Notation'
		case NUMBER_FORMAT_TYPE.E_NOTATION:
			return 'E Notation'
		case NUMBER_FORMAT_TYPE.US_DOLLAR:
			return 'US Dollar'
		case NUMBER_FORMAT_TYPE.COMPLEX_NUMBER:
			return 'Complex Number'
		case NUMBER_FORMAT_TYPE.COMPLEX_RECTANGULAR_I:
			return (
				<>
					Rectangular Using <InlineMath latex={'\\imaginaryI'} />
				</>
			)
		case NUMBER_FORMAT_TYPE.COMPLEX_RECTANGULAR_J:
			return (
				<>
					Rectangular Using <InlineMath latex={'\\imaginaryJ'} />
				</>
			)
		case NUMBER_FORMAT_TYPE.COMPLEX_POLAR_RADIAN:
			return 'Polar Using Radians'
		case NUMBER_FORMAT_TYPE.COMPLEX_POLAR_DEGREE:
			return 'Polar Using Degrees'
		default:
			return format
	}
}

export const isNumberFormatTypeComplex = (
	formatType: NUMBER_FORMAT_TYPE | undefined | null
): formatType is
	| NUMBER_FORMAT_TYPE.COMPLEX_RECTANGULAR_I
	| NUMBER_FORMAT_TYPE.COMPLEX_RECTANGULAR_J
	| NUMBER_FORMAT_TYPE.COMPLEX_POLAR_RADIAN
	| NUMBER_FORMAT_TYPE.COMPLEX_POLAR_DEGREE => {
	return !!formatType && COMPLEX_NUMBER_FORMAT_TYPES.includes(formatType)
}

const getDecimalPlacesFromRandomVariableParams = (
	numberToFormat: number,
	randomVariableParams: Partial<RandomNumberVariableParameters>
) => {
	const numberString = formatRandomNumberValue(
		randomVariableParams.min ?? '',
		randomVariableParams.max ?? '',
		randomVariableParams.step ?? '',
		new Decimal(numberToFormat)
	)
	const periodIndex = numberString.indexOf('.')
	const eIndex = numberString.indexOf('E')
	const decimalPlaces =
		periodIndex === -1 ? 0 : eIndex !== -1 ? eIndex - periodIndex - 1 : numberString.length - periodIndex - 1
	return decimalPlaces
}

const getNumberWithSeparators = (numberToFormat: string) => {
	// regex from https://stackoverflow.com/questions/2901102/how-to-print-a-number-with-commas-as-thousands-separators-in-javascript
	// NOTE: Safari and macOS Firefox DO NOT support negative look behind regex groupings yet
	const parts = numberToFormat.split('.')
	parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
	return parts.join('.')
}

const SAMPLE_NUMBER = 1.234321234321234321
const SAMPLE_IMAGINARY_NUMBER = 2.234321234321234321
const SAMPLE_POLAR_LENGTH = 1.4142135623730951
const SAMPLE_POLAR_ANGLE_RADIAN = 0.4853981633974483
const SAMPLE_POLAR_ANGLE_DEGREE = 45.234321234321234321

export const getSampleNumberFormat = (
	numberFormat: NUMBER_FORMAT_TYPE,
	rounding?: number,
	randomVariableParams?: Partial<RandomNumberVariableParameters>
) => {
	let formattedNumber = '0'
	const decimalPlaces =
		rounding !== undefined
			? rounding
			: randomVariableParams
			? getDecimalPlacesFromRandomVariableParams(SAMPLE_NUMBER, randomVariableParams)
			: 0

	switch (numberFormat) {
		case NUMBER_FORMAT_TYPE.NUMBER:
			formattedNumber = (SAMPLE_NUMBER * 1000).toFixed(decimalPlaces).toString()
			break
		case NUMBER_FORMAT_TYPE.NUMBER_WITH_SEPARATORS:
			formattedNumber = getNumberWithSeparators((SAMPLE_NUMBER * 1000).toFixed(decimalPlaces))
			break
		case NUMBER_FORMAT_TYPE.FRACTION:
			formattedNumber = '\\frac{3}{2}'
			break
		case NUMBER_FORMAT_TYPE.PERCENTAGE:
			formattedNumber = SAMPLE_NUMBER.toFixed(decimalPlaces).toString() + '\\%'
			break
		case NUMBER_FORMAT_TYPE.US_DOLLAR:
			formattedNumber = '\\$' + getNumberWithSeparators((SAMPLE_NUMBER * 1000).toFixed(decimalPlaces))
			break
		case NUMBER_FORMAT_TYPE.E_NOTATION:
			formattedNumber = SAMPLE_NUMBER.toFixed(decimalPlaces).toString() + 'E3'
			break
		case NUMBER_FORMAT_TYPE.SCI_NOTATION:
			formattedNumber = SAMPLE_NUMBER.toFixed(decimalPlaces).toString() + '\\times 10^{3}'
			break
		case NUMBER_FORMAT_TYPE.COMPLEX_RECTANGULAR_I:
			formattedNumber = `${SAMPLE_NUMBER.toFixed(decimalPlaces)}+${SAMPLE_IMAGINARY_NUMBER.toFixed(
				decimalPlaces
			)}\\imaginaryI `
			break
		case NUMBER_FORMAT_TYPE.COMPLEX_RECTANGULAR_J:
			formattedNumber = `${SAMPLE_NUMBER.toFixed(decimalPlaces)}+\\imaginaryJ ${SAMPLE_IMAGINARY_NUMBER.toFixed(
				decimalPlaces
			)}`
			break
		case NUMBER_FORMAT_TYPE.COMPLEX_POLAR_RADIAN:
			formattedNumber = `${SAMPLE_POLAR_LENGTH.toFixed(
				decimalPlaces
			)}\\: \\angle \\: ${SAMPLE_POLAR_ANGLE_RADIAN.toFixed(decimalPlaces)}\\pi `
			break
		case NUMBER_FORMAT_TYPE.COMPLEX_POLAR_DEGREE:
			formattedNumber = `${SAMPLE_POLAR_LENGTH.toFixed(
				decimalPlaces
			)}\\: \\angle \\: ${SAMPLE_POLAR_ANGLE_DEGREE.toFixed(decimalPlaces)}\\degree `
			break
	}

	return formattedNumber
}

//#region sample correct answer range calculation and formatting

/**
 * Method used to determine how many decimal places should be displayed for a sample number in the correct answer range. Exported only for testing.
 * @param numberString String representation of a number. Accepts E Notation strings, even if they are improperly formatted (e.g. 11E1)
 * @returns The number of fractional decimal places needed to represent a number in Decimal Notation
 */
export const getDecimalPlacesForSample = (numberString: string) => {
	const numberStringComponents = numberString.toUpperCase().split('E')
	const coefficient = numberStringComponents[0]
	const periodIndex = coefficient.indexOf('.')
	const exponent = numberStringComponents[1] ? +numberStringComponents[1] : 0
	// fractionalDigits is another name for the digits after the decimal point
	const fractionalDigits = periodIndex === -1 ? 0 : coefficient.length - periodIndex - 1
	const decimalPlaces = exponent > 0 && fractionalDigits <= exponent ? 0 : fractionalDigits - exponent
	return decimalPlaces
}

/**
 * Calculates correct answer range. Used only for calculating the sample tolerance displayed during problem creation. Exported only for testing.
 * Note that for sample numbers that will be displayed in E or Sci Notation, the correctAnswerRange is doing the calculation on the coefficients only.
 */
export const getCorrectAnswerRange = (
	correctAnswer: number,
	tolerance: number | null,
	toleranceType?: TOLERANCE_TYPE | null
) => {
	const correctAnswerDecimal = new Decimal(correctAnswer)
	let absoluteToleranceDecimal = new Decimal(0)
	const toleranceDecimal = new Decimal(tolerance ? tolerance : 0)
	if (!isNil(tolerance)) {
		switch (toleranceType) {
			case TOLERANCE_TYPE.UNIT:
				absoluteToleranceDecimal = toleranceDecimal
				break
			case TOLERANCE_TYPE.PERCENTAGE:
				absoluteToleranceDecimal = correctAnswerDecimal.times(toleranceDecimal).dividedBy(100).absoluteValue()
		}
	}

	const lowerLimit = correctAnswerDecimal.minus(absoluteToleranceDecimal)
	const lowerLimitString = lowerLimit.toString()
	const upperLimit = correctAnswerDecimal.plus(absoluteToleranceDecimal)
	const upperLimitString = upperLimit.toString()
	const lowerLimitDecimalPlaces = getDecimalPlacesForSample(lowerLimitString)
	const upperLimitDecimalPlaces = getDecimalPlacesForSample(upperLimitString)
	return [lowerLimit.toFixed(lowerLimitDecimalPlaces), upperLimit.toFixed(upperLimitDecimalPlaces)]
}

/**
 * Method used to format a sample number. All other formatting should happen in the solution engine
 * @param numberString string of number in decimal format that needs converted to sci or e notation
 * @param numberFormatType which notation to convert to (E or Sci only)
 */
const formatSciOrENotationForSampleNumber = (
	numberString: string,
	numberFormatType: NUMBER_FORMAT_TYPE.E_NOTATION | NUMBER_FORMAT_TYPE.SCI_NOTATION
) => {
	const firstNonZeroIndex = numberString.search(/[1-9]/)
	// Never found a non-zero number
	if (firstNonZeroIndex === -1) return numberString === NUMBER_FORMAT_TYPE.E_NOTATION ? '0E0' : '0\\times 10^{0}'
	const isNegative = numberString.startsWith('-')
	const decimalPosition = numberString.indexOf('.')
	// Get a string without leading zeroes, a decimal point, or a negative sign
	let onlyNumberString = numberString.slice(firstNonZeroIndex)
	if (decimalPosition > firstNonZeroIndex)
		onlyNumberString = onlyNumberString.slice(0, decimalPosition) + onlyNumberString.slice(decimalPosition + 1)

	const coefficient = Number(
		(isNegative ? '-' : '') +
			onlyNumberString[0] +
			(onlyNumberString.length > 1 ? '.' + onlyNumberString.substring(1) : '')
	)
	const exponent =
		decimalPosition === -1 ? numberString.length - firstNonZeroIndex - 1 : decimalPosition - firstNonZeroIndex - 1

	switch (numberFormatType) {
		case NUMBER_FORMAT_TYPE.E_NOTATION:
			return `${coefficient}E${exponent}`
		case NUMBER_FORMAT_TYPE.SCI_NOTATION:
			return `${coefficient}\\times 10^{${exponent}}`
	}
}

/**
 * Returns an array of formatted strings with the lower and upper limit of the
 * correct answer range based on the sample number and tolerance given
 */
export const getSampleCorrectAnswerRange = (
	numberFormat: NUMBER_FORMAT_TYPE,
	tolerance?: string | null,
	toleranceType?: TOLERANCE_TYPE | null,
	rounding?: number
) => {
	let correctAnswer = 0
	let correctAnswerRange: string[] = []
	const decimalPlaces = rounding || 0
	const tolerance_value = Number(tolerance)
	switch (numberFormat) {
		case NUMBER_FORMAT_TYPE.NUMBER:
			correctAnswer = Number((SAMPLE_NUMBER * 1000).toFixed(decimalPlaces))
			correctAnswerRange = getCorrectAnswerRange(correctAnswer, tolerance_value, toleranceType)
			if (tolerance_value === 0) return correctAnswerRange[0]
			else return `[${correctAnswerRange[0]},\\: ${correctAnswerRange[1]}]`
		case NUMBER_FORMAT_TYPE.NUMBER_WITH_SEPARATORS:
			correctAnswer = Number((SAMPLE_NUMBER * 1000).toFixed(decimalPlaces))
			correctAnswerRange = getCorrectAnswerRange(correctAnswer, tolerance_value, toleranceType)
			if (tolerance_value === 0) return getNumberWithSeparators(correctAnswerRange[0])
			else
				return `[${getNumberWithSeparators(correctAnswerRange[0])},\\: ${getNumberWithSeparators(
					correctAnswerRange[1]
				)}]`
		case NUMBER_FORMAT_TYPE.US_DOLLAR:
			correctAnswer = Number((SAMPLE_NUMBER * 1000).toFixed(decimalPlaces))
			correctAnswerRange = getCorrectAnswerRange(correctAnswer, tolerance_value, toleranceType)
			if (tolerance_value === 0) return `\\$${getNumberWithSeparators(correctAnswerRange[0])}`
			return `[\\$${getNumberWithSeparators(correctAnswerRange[0])},\\: \\$${getNumberWithSeparators(
				correctAnswerRange[1]
			)}]`
		case NUMBER_FORMAT_TYPE.E_NOTATION:
		case NUMBER_FORMAT_TYPE.SCI_NOTATION: {
			correctAnswer = Number(SAMPLE_NUMBER.toFixed(decimalPlaces)) * 1000
			correctAnswerRange = getCorrectAnswerRange(correctAnswer, tolerance_value, toleranceType)
			if (tolerance_value === 0) return formatSciOrENotationForSampleNumber(correctAnswerRange[0], numberFormat)
			return `[${formatSciOrENotationForSampleNumber(
				correctAnswerRange[0],
				numberFormat
			)},\\: ${formatSciOrENotationForSampleNumber(correctAnswerRange[1], numberFormat)}]`
		}
		case NUMBER_FORMAT_TYPE.COMPLEX_RECTANGULAR_I:
		case NUMBER_FORMAT_TYPE.COMPLEX_RECTANGULAR_J: {
			correctAnswer = Number(SAMPLE_NUMBER.toFixed(decimalPlaces))
			correctAnswerRange = getCorrectAnswerRange(correctAnswer, tolerance_value, toleranceType)
			const imaginaryRange = getCorrectAnswerRange(
				Number(SAMPLE_IMAGINARY_NUMBER.toFixed(decimalPlaces)),
				tolerance_value,
				toleranceType
			)
			const rangeDisplay =
				tolerance_value === 0
					? correctAnswerRange[0]
					: `[${correctAnswerRange[0]},\\: ${correctAnswerRange[1]}]`
			const imaginaryRangeDisplay =
				tolerance_value === 0 ? imaginaryRange[0] : `[${imaginaryRange[0]},\\: ${imaginaryRange[1]}]`
			return numberFormat === NUMBER_FORMAT_TYPE.COMPLEX_RECTANGULAR_I
				? `${rangeDisplay}+${imaginaryRangeDisplay}\\imaginaryI `
				: `${rangeDisplay}+\\imaginaryJ ${imaginaryRangeDisplay}`
		}
		case NUMBER_FORMAT_TYPE.COMPLEX_POLAR_RADIAN:
		case NUMBER_FORMAT_TYPE.COMPLEX_POLAR_DEGREE: {
			// hybrid tolerance for polar complex formats uses percentage for magnitude and unit for angle
			correctAnswer = Number(SAMPLE_POLAR_LENGTH.toFixed(decimalPlaces))
			correctAnswerRange = getCorrectAnswerRange(correctAnswer, tolerance_value, TOLERANCE_TYPE.PERCENTAGE)
			const correctAnswerAngle =
				numberFormat === NUMBER_FORMAT_TYPE.COMPLEX_POLAR_RADIAN
					? Number(SAMPLE_POLAR_ANGLE_RADIAN.toFixed(decimalPlaces))
					: Number(SAMPLE_POLAR_ANGLE_DEGREE.toFixed(decimalPlaces))
			const correctAnswerAngleRange = getCorrectAnswerRange(
				correctAnswerAngle,
				numberFormat === NUMBER_FORMAT_TYPE.COMPLEX_POLAR_RADIAN
					? // For radians, use the provided tolerance amount * pi/180
					  tolerance_value * (Math.PI / 180)
					: tolerance_value,
				TOLERANCE_TYPE.UNIT
			)
			const rangeDisplay =
				tolerance_value === 0
					? correctAnswerRange[0]
					: `[${correctAnswerRange[0]},\\: ${correctAnswerRange[1]}]`
			const angleRangeDisplay =
				tolerance_value === 0
					? correctAnswerAngleRange[0]
					: `[${correctAnswerAngleRange[0]},\\: ${correctAnswerAngleRange[1]}]`
			return `${rangeDisplay}\\: \\angle \\: ${angleRangeDisplay}${
				numberFormat === NUMBER_FORMAT_TYPE.COMPLEX_POLAR_DEGREE ? '\\degree ' : '\\pi '
			}`
		}
		default:
			return ''
	}
}
//#endregion sample correct answer range calculation and formatting

/**
	Regex pattern for Numbers inside a Complex Number, one of the following alternatives
	- one or more digits, optionally followed by groups of 3 digits separated by commas
	- a period and one or more digits, optionally preceded by zero or more digits followed by optional groups of 3 digits separated by commas
 */
const numberInsideComplexPattern = `(?:\\d+(?:,\\d{3})*|\\d*(?:,\\d{3})*\\.\\d+)`

/**
	Regex pattern for Sci Notation formatted values
	- optional '-'
	- optional non-capturing group
		- zero or more digits
		- period
	- one or more digits
	- '\times 10^' exactly
	- non-capturing group
		- either a single digit
		- or brackets '{}' wrapping optional '-' and one or more digits
 */
const sciNotationPattern = `-?(?:\\d*\\.)?\\d+\\\\times 10\\^(?:\\d|(?:\\{-?\\d+\\}))`

/**
	Regex pattern for E Notation formatted values
	- optional '-'
	- optional non-capturing group
		- zero or more digits
		- period
	- one or more digits
	- 'E' exactly
	- optional '-'
	- one or more digits
 */
const eNotationPattern = `-?(?:\\d*\\.)?\\d+E-?\\d+`

const optionalMultiplicationCmds = `(?:\\*|\\\\times ?|\\\\cdot ?)?`
const angleCmd = `\\\\angle `
const degreeCmd = `\\\\degree ?`
const piCmd = `\\\\pi ?`
const imaginaryICmd = `\\\\imaginaryI ?`
const imaginaryJCmd = `\\\\imaginaryJ ?`
const fracCmd = `\\\\frac`

const fractionNumberPattern = `${fracCmd}{-?${numberInsideComplexPattern}}{-?${numberInsideComplexPattern}}`

const complexNumberNumericValuePattern = `(?:${numberInsideComplexPattern}|${fractionNumberPattern}|${sciNotationPattern}|${eNotationPattern})`

const complexNumberRadiansPattern = `${numberInsideComplexPattern}(?:${optionalMultiplicationCmds}${piCmd})?|(?:${numberInsideComplexPattern}${optionalMultiplicationCmds})?${piCmd}`
const complexNumberRadianFractionRegex = `${fracCmd}{-?(?:${complexNumberRadiansPattern})}{-?${numberInsideComplexPattern}}`

const complexNumberRectangularImIExactPattern = `(?:(?:${complexNumberNumericValuePattern})?${imaginaryICmd})`
const complexNumberRectangularImIPattern = `(?:${complexNumberRectangularImIExactPattern}|${imaginaryICmd}(?:${complexNumberNumericValuePattern})?)`
const complexNumberRectangularImJExactPattern = `(?:${imaginaryJCmd}(?:${complexNumberNumericValuePattern})?)`
const complexNumberRectangularImJPattern = `(?:${complexNumberRectangularImJExactPattern}|(?:${complexNumberNumericValuePattern})?${imaginaryJCmd})`

/**
	Regex for Complex Number Rectangular I Format
	- start of string
	- optional + or -
	- non-capturing group, one of the following alternatives
	- 1. `a+bi`
		- optional numeric value (number, fraction, E-notation, Sci-notation) followed by a + or -
		- non-capturing group, one of the following alternatives, a.k.a. `bi`, `ib`, or `i`
			- an imaginary i, optionally preceded by a numeric value
			- an imaginary i, optionally followed by a numeric value
	- we also need to play reverse uno card on the whole first string to account for the imaginary part coming before the real part (2i+2) below is the explanation of the reverse
	- 2. `bi+a`
		- non-capturing group, one of the following alternatives, a.k.a. `bi`, `ib`, or `i`
		- real number or fraction followed by an imaginary i or j
			- an imaginary i, optionally preceded by a numeric value
			- an imaginary i or j
		- optional + or - followed by a numeric value
	- end of string
 */
export const latexRectangularIComplexNumberRegEx = new RegExp(
	`^[+-]?(?:(?:${complexNumberNumericValuePattern}[+-])?${complexNumberRectangularImIPattern}|${complexNumberRectangularImIPattern}(?:[+-]${complexNumberNumericValuePattern})?)$`,
	'gm'
)

/** 
	Regex for **Exact** Complex Number Rectangular I Format
	Only matches the `a+bi` format, and does NOT match `a+ib`, `bi+a` etc.
*/
export const latexRectangularIComplexNumberExactRegEx = new RegExp(
	`^[+-]?(?:${complexNumberNumericValuePattern}[+-])?${complexNumberRectangularImIExactPattern}$`,
	'gm'
)

/**
	Regex for Complex Number Rectangular J Format
	- start of string
	- optional + or -
	- non-capturing group, one of the following alternatives
	- 1. `a+jb`
		- optional numeric value (number, fraction, E-notation, Sci-notation) followed by a + or -
		- non-capturing group, one of the following alternatives, a.k.a. `jb`, `bj`, or `j`
			- an imaginary j, optionally preceded by a numeric value
			- an imaginary j, optionally followed by a numeric value
	- we also need to play reverse uno card on the whole first string to account for the imaginary part coming before the real part (j2+2) below is the explanation of the reverse
	- 2. `jb+a`
		- non-capturing group, one of the following alternatives, a.k.a. `jb`, `bj`, or `j`
		- real number or fraction followed by an imaginary i or j
			- an imaginary j, optionally preceded by a numeric value
			- an imaginary j or j
		- optional + or - followed by a numeric value
	- end of string
 */
export const latexRectangularJComplexNumberRegEx = new RegExp(
	`^[+-]?(?:(?:${complexNumberNumericValuePattern}[+-])?${complexNumberRectangularImJPattern}|${complexNumberRectangularImJPattern}(?:[+-]${complexNumberNumericValuePattern})?)$`,
	'gm'
)

/** 
	Regex for **Exact** Complex Number Rectangular J Format
	Only matches the `a+jb` format, and does NOT match `a+bj`, `jb+a` etc.
*/
export const latexRectangularJComplexNumberExactRegEx = new RegExp(
	`^[+-]?(?:${complexNumberNumericValuePattern}[+-])?${complexNumberRectangularImJExactPattern}$`,
	'gm'
)

/**
	Regex for Complex Number Polar Format using Degrees
	- start of string
	- optional '-'
	- numeric value (number, fraction, E-notation, Sci-notation)
	- '\angle ' exactly
	- optional '-'
	- number
	- '\degree ' exactly
	- end of string
 */
export const latexPolarDegreeComplexNumberRegEx = new RegExp(
	`^-?${complexNumberNumericValuePattern}${angleCmd}-?${numberInsideComplexPattern}${degreeCmd}$`,
	'gm'
)

/**
	Regex for Complex Number Polar Format using Radians
	- start of string
	- optional '-'
	- numeric value (number, fraction, E-notation, Sci-notation)
	- '\angle ' exactly
	- optional '-'
	- non-capturing group, one of the following alternatives
		- number, optionally followed by pi with an optional multiplication cmd
		- pi, optionally preceded by a number with an optional multiplication cmd
		- a fraction containing pi in the numerator
		- a fraction number followed by pi
	- end of string
 */
export const latexPolarRadianComplexNumberRegEx = new RegExp(
	`^-?${complexNumberNumericValuePattern}${angleCmd}-?(?:${complexNumberRadiansPattern}|${complexNumberRadianFractionRegex}|${fractionNumberPattern}${piCmd})$`,
	'gm'
)

const hasInvalidSciNotation = (latex: string, invalidSymbols: string[]) => {
	// reset regex
	latexSciNotationRegEx.lastIndex = 0
	return (
		(invalidSymbols.includes('x') ||
			latex.includes('times') ||
			latex.includes('^') ||
			latex.includes('x') ||
			latex.includes('*')) &&
		!latexSciNotationRegEx.test(latex)
	)
}

const hasInvalidENotation = (latex: string) => {
	// reset regex
	latexENotationRegEx.lastIndex = 0
	// "e" without another letter before, or "E"
	return (/(?<![a-zA-Z])e/g.test(latex) || latex.includes('E')) && !latexENotationRegEx.test(latex)
}

/**
 * Find which number formats that a student’s answer partially includes, but is not yet valid.
 *
 * If the student’s answer must match the correct answer’s format, then that specific format is returned if it does not match.
 *
 * @param answer The student’s answer, which is possibly invalid
 * @param invalidSymbols Any symbols from mathlive that are flagged as invalid, used to determine validity
 * @param numberFormatType The format of the solution instance / correct answer, which is optionally required using `mustMatchNumberFormat`
 * @param mustMatchNumberFormat If true, then the `answer` must match the format of `numberFormatType`
 */
export const getInvalidMatchingFormats = (
	answer: string,
	invalidSymbols: string[],
	numberFormatType: NUMBER_FORMAT_TYPE | null = NUMBER_FORMAT_TYPE.NUMBER,
	mustMatchNumberFormat?: boolean
) => {
	// if format is not complex, just check if the answer is invalid Sci or E notation
	if (!isNumberFormatTypeComplex(numberFormatType)) {
		if (hasInvalidSciNotation(answer, invalidSymbols)) {
			return [NUMBER_FORMAT_TYPE.SCI_NOTATION]
		}
		if (hasInvalidENotation(answer)) {
			return [NUMBER_FORMAT_TYPE.E_NOTATION]
		}
		return []
	}

	const invalidMatchingFormats: Set<NUMBER_FORMAT_TYPE> = new Set()

	// reset complex number regular expressions
	latexRectangularIComplexNumberExactRegEx.lastIndex = 0
	latexRectangularIComplexNumberRegEx.lastIndex = 0
	latexRectangularJComplexNumberExactRegEx.lastIndex = 0
	latexRectangularJComplexNumberRegEx.lastIndex = 0
	latexPolarDegreeComplexNumberRegEx.lastIndex = 0
	latexPolarRadianComplexNumberRegEx.lastIndex = 0

	const matchesRectangularI = latexRectangularIComplexNumberRegEx.test(answer)
	const matchesRectangularJ = latexRectangularJComplexNumberRegEx.test(answer)
	const matchesPolarDegree = latexPolarDegreeComplexNumberRegEx.test(answer)
	const matchesPolarRadian = latexPolarRadianComplexNumberRegEx.test(answer)

	if (mustMatchNumberFormat) {
		// a specific format is required, check if the answer matches exactly
		if (
			(numberFormatType === NUMBER_FORMAT_TYPE.COMPLEX_RECTANGULAR_I &&
				!latexRectangularIComplexNumberExactRegEx.test(answer)) ||
			(numberFormatType === NUMBER_FORMAT_TYPE.COMPLEX_RECTANGULAR_J &&
				!latexRectangularJComplexNumberExactRegEx.test(answer)) ||
			(numberFormatType === NUMBER_FORMAT_TYPE.COMPLEX_POLAR_DEGREE && !matchesPolarDegree) ||
			(numberFormatType === NUMBER_FORMAT_TYPE.COMPLEX_POLAR_RADIAN && !matchesPolarRadian)
		) {
			invalidMatchingFormats.add(numberFormatType)
		}
	} else {
		// one specific format is not required, check for invalid matches

		// rectangular
		const containsI = invalidSymbols.includes('i') || answer.includes('\\imaginaryI')
		const containsJ = invalidSymbols.includes('j') || answer.includes('\\imaginaryJ')
		const containsPlus = answer.includes('+')
		// minus sign after a digit, sci notation, or fraction
		const containsMinusAfterDigitOrBrace = /(?:\d|\})-/g.test(answer)

		if (containsI && !matchesRectangularI) {
			invalidMatchingFormats.add(NUMBER_FORMAT_TYPE.COMPLEX_RECTANGULAR_I)
		}
		if (containsJ && !matchesRectangularJ) {
			invalidMatchingFormats.add(NUMBER_FORMAT_TYPE.COMPLEX_RECTANGULAR_J)
		}
		if (
			(containsPlus || containsMinusAfterDigitOrBrace) &&
			!containsI &&
			!containsJ &&
			!matchesRectangularI &&
			!matchesRectangularJ
		) {
			invalidMatchingFormats.add(NUMBER_FORMAT_TYPE.COMPLEX_RECTANGULAR_I)
			invalidMatchingFormats.add(NUMBER_FORMAT_TYPE.COMPLEX_RECTANGULAR_J)
		}

		// polar
		const containsAngle = answer.includes('\\angle')
		const containsDegree = answer.includes('\\degree')
		if (containsAngle && !matchesPolarRadian && !matchesPolarDegree) {
			invalidMatchingFormats.add(NUMBER_FORMAT_TYPE.COMPLEX_POLAR_DEGREE)
			if (!containsDegree) {
				invalidMatchingFormats.add(NUMBER_FORMAT_TYPE.COMPLEX_POLAR_RADIAN)
			}
		}
		if (containsDegree && !matchesPolarDegree) {
			invalidMatchingFormats.add(NUMBER_FORMAT_TYPE.COMPLEX_POLAR_DEGREE)
		}
		if (answer.includes('\\pi') && !matchesPolarRadian) {
			invalidMatchingFormats.add(NUMBER_FORMAT_TYPE.COMPLEX_POLAR_RADIAN)
		}
	}

	// check for invalid Sci Notation or E Notation numeric parts inside complex number
	// split polar format at angle
	const parts = answer.includes('\\angle')
		? answer.split('\\angle')
		: // split rectangular format at minus or plus sign
		/(?:\d|\})-/.test(answer)
		? answer.split(/(?<=\d|\})-/)
		: answer.split('+')
	parts.forEach(part => {
		// strip out complex number commands and symbols
		const numericPart = part.replace(/\\imaginaryI|\\imaginaryJ|\\angle|\\degree|\\pi|(?<!t)i|j/g, '').trim()
		getInvalidMatchingFormats(numericPart, []).forEach(f => invalidMatchingFormats.add(f))
	})

	return Array.from(invalidMatchingFormats)
}

const complexNumberRangeRegex = new RegExp(/^\[(?:(\[.*?,.*?]), )+(\[.*?,.*?])]$/)
/**
 * Format the ranges for the real and imaginary parts of complex numbers.
 *
 * @param answer The correct answer string
 * @param range Range string of the format [[a, b], [c, d]]
 * @param formatType The format type of the number, should be one of the complex number types
 * @returns A string including both number ranges based on the format type. Will return an empty string if the range is
 * invalid or the format type is not a complex number type.
 */
export const formatComplexAnswerRange = (answer: string, range: string, formatType: NUMBER_FORMAT_TYPE) => {
	const rangeStrings = complexNumberRangeRegex.exec(range)?.slice(1)
	if (!rangeStrings || rangeStrings.length !== 2) {
		return ''
	}

	switch (formatType) {
		case NUMBER_FORMAT_TYPE.COMPLEX_RECTANGULAR_I:
			return `${rangeStrings[0]}+${rangeStrings[1]}\\imaginaryI `
		case NUMBER_FORMAT_TYPE.COMPLEX_RECTANGULAR_J:
			return `${rangeStrings[0]}+\\imaginaryJ ${rangeStrings[1]}`
		case NUMBER_FORMAT_TYPE.COMPLEX_POLAR_RADIAN:
			return `${rangeStrings[0]}\\: \\angle \\: ${rangeStrings[1]}${answer.includes('\\pi') ? '\\pi ' : ''}`
		case NUMBER_FORMAT_TYPE.COMPLEX_POLAR_DEGREE:
			return `${rangeStrings[0]}\\: \\angle \\: ${rangeStrings[1]}\\degree `
		default:
			// Only supporting complex number types, return an empty string for other types
			return ''
	}
}

export const padAngleCommands = (text: string) => text.replace(/\\angle /gm, '\\: \\angle \\: ')

export const stripSpaceCommands = (text: string) => text.replace(/\\: |\\space /gm, '')
